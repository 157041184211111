import React from "react"

// Libraries
import styled from "styled-components"
import { Link } from "gatsby"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Container from "components/container"
import { ButtonLink } from "components/buttons"

const StyledSection = styled.section`
  padding: 60px 0;
  border-bottom-width: 0;

  ${breakpoint.medium`
    padding: 124px 0;
  `}

  .title {
    max-width: 280px;
    text-align: center;
    margin: 0 auto 30px auto;

    ${breakpoint.small`
      max-width: 100%;
      width: 100%;
    `}

    ${breakpoint.medium`
      margin: 0 0 40px 0;
    `}
  }

  .content {
    width: 100%;
    display: ${props => (props.contentIsVisible ? "flex" : "none")};
    flex-wrap: wrap;
    justify-content: space-between;

    &.visible {
      display: flex;
    }

    &:last-child {
      margin-bottom: 0;
    }

    p {
      width: 100%;
      margin-bottom: 32px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .load-more {
    width: 100%;
    display: ${props => (props.contentIsVisible ? "none" : "")};
    padding: 55px 28px 60px 28px;
    text-align: center;
    border-bottom: 1px solid ${colors.charcoal};

    ${breakpoint.medium`
      margin-top: 60px;
      padding: 0;
      border: 0;
    `}

    button {
      max-width: 320px;
      background-color: ${colors.orange};
      color: ${colors.charcoal};

      &:hover {
        background-color: ${colors.charcoal};
        color: ${colors.white};
      }
    }
    
    ${ButtonLink} {
      width: 100%;

      ${breakpoint.medium`
        width: auto;
      `}
    }
  }
`

class Section extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showLoadMore: this.props.showLoadMore,
      contentIsVisible: false,
    }
  }

  render = props => (
    <StyledSection contentIsVisible={this.state.contentIsVisible} data-aos="fade-up">
      <Container>
        <h2 className="title">{this.props.title}</h2>
        {this.props.children}
      </Container>
    </StyledSection>
  )
}

const Legal = () => {
  return (
    <React.Fragment>
      <Section title="Privacy Policy" showLoadMore>
        <div className="content visible">
          <p>Visby Medical, Inc. (“we”, “us” or “our”) is committed to protecting your privacy. We have created this
            Privacy Policy to inform you of our policies regarding the collection, use and disclosure of personal
            information and the choices you have associated with that information.</p>
          <p>This Privacy Policy applies to all personal information collected or processed online or offline through:
            the website located at <Link to="/">www.visbymedical.com</Link>, and
            all corresponding webpages and websites that link to this “Privacy Policy (the “Site”); any Visby mobile
            application; our diagnostic devices; and laboratory analyses (collectively, the “Services”).</p>
        </div>
        <div className="load-more">
          <ButtonLink backgroundcolor="primary" to="/legal/privacy-policy/">
            Read More
          </ButtonLink>
        </div>
      </Section>

      <Section title="Website Terms of Service" showLoadMore>
        <div className="content visible">
          <p>This website is operated by Visby Medical, Inc. Throughout the site, the terms “Visby”, “we”, “us” and
            “our” refer to Visby Medical. Visby offers this website, including all services, tools, technology,
            products, features, and mobile apps (together, the “Services”) available from this site to you, the user,
            conditioned upon your acceptance of all terms, conditions, policies and notices stated here.</p>
          <p>By visiting our site and/or purchasing something from us, you engage in our “Service” and agree to be
            bound by the following terms and conditions (“Terms of Service”, “Terms”), including those additional terms
            and conditions and policies referenced herein and/or available by hyperlink. These website Terms of Service apply to
            all users of the site, including without limitation users who are browsers, vendors, customers, merchants,
            and/or contributors of content.</p>
        </div>
        <div className="load-more">
          <ButtonLink backgroundcolor="primary" to="/legal/terms/">
            Read More
          </ButtonLink>
        </div>
      </Section>

      <Section title="Terms and Conditions of Sale">
        <div className="content visible">
          <p>
            Visby Medical, Inc. (“Seller”) hereby offers for sale to buyer (“Buyer”) the molecular diagnostic products
            and accessories listed on an Offer (defined below) or Seller’s product catalog or price list (the “Products”)
            on the express condition that Buyer agrees to accept and be bound by the terms and conditions set forth herein
            (these “Terms &amp; Conditions”) and in any written  quotation issued by Seller to Buyer, (ii) signed purchase
            agreement between Seller and Buyer, or (iii) Seller’s pro forma invoice (each of the foregoing (i), (ii) and (iii) an “Offer”).
          </p>
          <p>
            These Terms &amp; Conditions and any Offer (collectively, the “Agreement”), provide the complete and exclusive
            agreement between Seller and Buyer with respect to Buyer’s purchase of the Products. To the extent of
            any conflict or inconsistency between an Offer and these Terms &amp; Conditions, the Offer shall control. Any
            provisions contained in any document issued by Buyer related to the purchase of the Products are expressly rejected.
          </p>
        </div>
        <div className="load-more">
          <ButtonLink backgroundcolor="primary" to="/legal/conditions/">
            Read More
          </ButtonLink>
        </div>
      </Section>

      <Section title="Visby Patents">
        <div className="content visible">
          <p>
            The following U.S. patents apply to the Visby products listed here. This list is posted on the Internet to provide notice to the
            public in compliance with the virtual patent marking provisions of 35
            USC 287. Updated: 06/05/2020
          </p>
          <p>
            Product: Visby Covid-19 Diagnostic
            <br />
            Patent Nos.: 8,263,392 | 10,124,334 | 10,052,629 | 10,456,783 | 10,525,469 | D800,331
          </p>
        </div>
        <div className="load-more">
          <ButtonLink backgroundcolor="primary" to="/legal/patents/">
            Read More
          </ButtonLink>
        </div>
      </Section>

    </React.Fragment>
  )
}
export default Legal
